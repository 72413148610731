























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Slot from '@/models/Slot.model';
import {SlotType} from '@/enums/SlotType.enum';
import {DataTableHeader} from 'vuetify';
import {ShiftTypeSpecial} from '@/enums/ShiftTypeSpecial.enum';
import {namespace} from 'vuex-class';
import {slotStoreActions} from '@/store/slot.store';
import {DateTime} from 'luxon';
import {UserRole} from '@/models/User.model';
import ShipArrival from '@/models/ShipArrival.model';
import {BookingState} from '@/enums/BookingState.enum';
import {settingsStoreGetter} from "@/store/settings.store";
import {SlotSettings} from "@/interfaces/SlotSettings.interface";

const SlotStore = namespace('slot');
const SettingsStore = namespace('settings');

@Component({
    components: {
        ShipArrivalDetailsComponent: () => import(
            /* webpackChunkName: "ShipArrivalDetailsComponent" */
            '@/components/shipArrival/ShipArrivalDetails.component.vue'),
    }
})
export default class SlotBookingsTableComponent extends Vue {

    @SettingsStore.Getter(settingsStoreGetter.SETTINGS)
    public settings!: SlotSettings;

    private currentDateTime: DateTime = DateTime.utc();
    /**
     * Minimal date for booking (normally 5 days in future from today)
     */
    public minDate: DateTime | null = null;

    @SlotStore.Action(slotStoreActions.DELETE_SLOT)
    public deleteSlot!: (payload: { slot: Slot }) => Promise<Slot>

    public SlotType = SlotType;
    public ShiftTypeSpecial = ShiftTypeSpecial;

    public showConfirmDialog: boolean = false;
    public selectedSlot?: Slot;

    @Prop({default: () => []})
    public slots!: Slot[];

    @Prop({default: () => []})
    public shipArrival!: ShipArrival;

    @Watch('settings.lockedAmount', { immediate: true })
    public lockedAmountChanged() {
        this.minDate = this.currentDateTime
            .plus({days: this.settings.lockedAmount})
            .startOf('day');
    }

    public mounted() {
        this.minDate = this.currentDateTime
            .plus({days: this.settings.lockedAmount})
            .startOf('day');
    }

    public isDeletable(slot: Slot) {
        if (this.$hasRole([UserRole.KEY_USER, UserRole.IT_AUTOMOBILE, UserRole.PLANER]))
            return true;

        if (this.shipArrival.booking &&
            [BookingState.ACCEPTED, BookingState.DECLINED, BookingState.CANCELED].includes(this.shipArrival.booking.status!)) {
            return false;
        }

        return this.minDate! <= DateTime.fromISO(slot.date);
    }

    public tableHeaders: DataTableHeader[] = [
        {
            text: this.$t('SLOT_BOOKING.TABLE.SLOT_TYPE').toString(),
            value: 'slotType',
            sortable: false,
            align: 'center',
            width: 84,
            class: 'surface'
        }, {
            text: this.$t('SLOT_BOOKING.TABLE.DATE').toString(),
            value: 'date',
            sortable: true,
            class: 'surface'
        }, {
            text: this.$t('SLOT_BOOKING.TABLE.SHIFT').toString(),
            value: 'shift',
            sortable: false,
            class: 'surface'
        }, {
            text: this.$t('SLOT_BOOKING.TABLE.SHIFT_COMMENT').toString(),
            value: 'shiftComment',
            sortable: false,
            class: 'surface'
        }, {
            text: this.$t('SLOT_BOOKING.TABLE.AMOUNT').toString(),
            value: 'amount',
            sortable: false,
            class: 'surface'
        }, {
            text: this.$t('SLOT_BOOKING.TABLE.OPTIONS').toString(),
            value: 'options',
            align: 'end',
            sortable: false,
            class: 'surface'
        }];

    /**
     * Calculate height for table
     */
    public calculateHeight(): number {
        const minHeight = 130;
        const maxHeight = 5 * 50 + 50 + 10; // 50 default row height + 50 -> header + 10 -> offset
        const currentHeight = this.slots.length * 50 + 50 + 15;
        return Math.max(minHeight, Math.min(currentHeight, maxHeight));
    }

    public async deleteSlotClicked(slot: Slot) {
        this.selectedSlot = slot;
        this.showConfirmDialog = true;
    }

    public closeConfirmDialog() {
        this.showConfirmDialog = false;
        this.selectedSlot = undefined;
    }

    public async deleteSlotConfirmed() {
        await this.deleteSlot({slot: this.selectedSlot!});
        this.selectedSlot = undefined;
        this.showConfirmDialog = false;
        this.$emit('reloadData');
    }
}

