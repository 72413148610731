var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slot-booking-table mx-n8 px-8 surface"},[_c('v-data-table',{staticClass:"surface",attrs:{"items":_vm.slots,"height":_vm.calculateHeight(),"fixed-header":"","headers":_vm.tableHeaders,"no-data-text":_vm.$t('SLOT_BOOKING.NO_BOOKINGS_AVAILABLE'),"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.$isoStringDateHumanized(item.date)))])]}},{key:"item.shift",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t(("SHIFTS." + (item.shift)))))])]}},{key:"item.shiftComment",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.shiftComment))])]}}],null,true)},[(item.shiftComment === _vm.ShiftTypeSpecial.TG)?_c('span',[_vm._v("TG: 08:00-16:00")]):_vm._e(),(item.shiftComment === _vm.ShiftTypeSpecial.MS1)?_c('span',[_vm._v("MS1: 10:00-18:00")]):_vm._e(),(item.shiftComment === _vm.ShiftTypeSpecial.MS2)?_c('span',[_vm._v("MS2: 18:00-02:00")]):_vm._e()])]}},{key:"item.slotType",fn:function(ref){
var item = ref.item;
return [(item.slotType === _vm.SlotType.CAR)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-car")]):_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"d-flex flex-column justify-center align-center mr-1"},[_c('v-icon',{staticClass:"mb-1",attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-truck")]),_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-tractor")])],1),_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-box")])],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(item.amount))])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(_vm.isDeletable(item))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteSlotClicked(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1):_vm._e()],1)]}}])}),_c('confirm-dialog-component',{attrs:{"showDialog":_vm.showConfirmDialog},on:{"confirmed":_vm.deleteSlotConfirmed,"closed":_vm.closeConfirmDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }